import React, { Fragment } from 'react';
import StepContainer from '../../stepContainer/StepContainer';
import '../Pages.css';

const About = () => {
	return (
		<Fragment>
			<StepContainer>
				<div className="legal-disclaimer">
					<h3>About Us</h3>
					<p>
						We’re a team of techie marketers that have come together
						to create a service that allows consumers to show for
						and save money on their auto nsurance.
					</p>
					<p>
						As a team we offer the unique ability to leverage our
						relationships with insurance providers and apply our
						tech skills to make shopping for insurance easier than
						contacting a local insurance agent.
					</p>
					<p>
						Our goal is to simplify the process of shopping for an
						insurance quote online, and offer the lowest rates with
						the best coverage for auto insurance policies.
					</p>
					<p>
						Getting a quote is easy!{' '}
						<a href="/" target="_blank">
							Click here to get your quote now.
						</a>
					</p>
				</div>
			</StepContainer>
		</Fragment>
	);
};

export default About;
