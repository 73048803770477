import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStateValue } from '../../StateProvider';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import Spinner from '../spinner/Spinner';
import StepContainer from '../stepContainer/StepContainer';
import { AddNewLead } from '../../Services';
import PhoneInput from 'react-phone-input-2';
import InputMask from 'react-input-mask';
import './Complete.css';
import { actionTypes } from '../../reducer';
import { toast } from 'react-toastify';

const Complete = ({ progress }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const [
		{ wizardData, flowData, progressData, campaignData },
		dispatch
	] = useStateValue();
	const [ isMobileDevice, setIsMobileDevice ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const history = useHistory();

	// disabled resize logic
	// useEffect(
	// 	() => {
	// 		let windowWidth = window.innerWidth;

	// 		if (windowWidth <= 767) {
	// 			setIsMobileDevice(true);
	// 		}

	// 		setIsLoading(false);
	// 	},
	// 	[ window.innerWidth ]
	// );

	useEffect(() => {
		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});

		window.gtag('event', 'page_view', {
			page_title : 'Quote Form',
			page_path  : window.location.href
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handlePhoneChange = (value, country, e, formattedValue) => {
		console.log(value, country, e, formattedValue);
		setFormData({
			...formData,
			['Phone'] : formattedValue
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let tempData = {
			...wizardData,
			...flowData,
			...formData,
			...campaignData
		};

		if (
			!tempData.insuranceProvider ||
			tempData.insuranceProvider.trim() === ''
		) {
			tempData.insuranceProvider = 'Other';
		}

		if (!tempData.zipCode || tempData.zipCode.trim() === '') {
			tempData.zipCode = '00000';
		}

		let isValid = true;

		if (!tempData.firstName || tempData.firstName.trim() === '') {
			isValid = false;
		}

		if (!tempData.lastName || tempData.lastName.trim() === '') {
			isValid = false;
		}

		if (!tempData.Phone || tempData.Phone.trim() === '') {
			isValid = false;
		}

		if (!isValid) {
			toast.error('All 3 fields are required');
			return;
		}

		// console.log(tempData);

		setIsSaving(true);

		const response = await AddNewLead(tempData);

		history.push('/thank-you');
	};

	return (
		<Fragment>
			{!isLoading ? (
				<Fragment>
					{!isMobileDevice ? (
						<Fragment>
							<StepContainer>								
								<h3 className="get-quote-subtitle">
									It Looks Like You Could <u>Save Some Money</u>!!!
								</h3>

								<h2 className="lets-get-you-quote-title">
									Let Us Give You A Quote And Get You The Most Savings:
								</h2>								

								<div className="inputs-container">
									<form onSubmit={handleSubmit}>
										<input
											type="text"
											className="form-control form-control-lg mb-3"
											name="firstName"
											placeholder="First Name"
											value={formData.firstName}
											onChange={handleChange}
											disabled={isSaving}
										/>

										<input
											type="text"
											className="form-control form-control-lg mb-3"
											name="lastName"
											placeholder="Last Name"
											value={formData.lastName}
											onChange={handleChange}
											disabled={isSaving}
										/>

										<InputMask
											mask="(999) 999-9999"
											value={formData.Phone}
											onChange={handleChange}
											disabled={isSaving}
										>
											<input
												type="tel"
												className="form-control form-control-lg mb-3"
												name="Phone"
												placeholder="Phone"
												disabled={isSaving}
											/>
										</InputMask>

										<div className="buttons-container">
											<button
												type="submit"
												className="btn btn-primary btn-lg btn-block"
												disabled={isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<span>
															Get My Quote
														</span>
													</Fragment>
												) : (
													<Fragment>
														<div
															className="spinner-border"
															role="status"
														/>
													</Fragment>
												)}
											</button>
										</div>
									</form>
								</div>
								<p className="text-left mt-5">
									Enter your name and number above and one of
									our auto insurance specialists will do all
									the hard work and research{' '}
									<span className="red">
										every possible special promotion and
										discount available
									</span>{' '}
									based on the information you provided, and
									then{' '}
									<strong>
										<u>send you a text message</u>
									</strong>{' '}
									with the absolute best possible quote they
									can find.
								</p>
							</StepContainer>

							<div className="container">
								<div className="disclaimer-container">
									<small>
										By clicking "Get My Quote" and
										submitting this form, I am providing
										express written consent to being
										contacted by you,{' '}
										{campaignData.companyName}, or by one or
										more agents or brokers of your{' '}
										<a
											href="/pages/partners"
											target="_blank"
										>
											partners
										</a>{' '}
										which companies I agree may reach me to
										discuss my interest, including offers of
										insurance, at the phone number and/or
										email address I have provided to you in
										submitting this form and/or additional
										information obtained. I consent by
										electronic signature to being contacted
										by telephone (via call and/or text) for
										marketing/telemarketing purposes at the
										phone number I provided in this form,
										even if my phone number is listed on a
										Do Not Call Registry, and I agree that
										such contact may be made using an
										automatic telephone dialing system
										and/or an artificial or prerecorded
										voice (standard call, text message, and
										data rates apply). I can revoke my
										consent at any time. I also understand
										that my agreement to be contacted is not
										a condition of purchasing any property,
										goods or services, and that I may call
										{` `}
										{wizardData.clickToCallNumber} to speak
										with someone about obtaining an
										insurance quote. By clicking Call Me and
										submitting this form, I affirm that I
										have read and agree to this website’s{' '}
										<a
											href="/pages/privacy"
											target="_blank"
										>
											Privacy Policy
										</a>{' '}
										and{' '}
										<a href="/pages/terms" target="_blank">
											Terms of Use
										</a>, including the arbitration
										provision and the E-SIGN Consent.
									</small>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="call-now-mobile-container">
								<h1>Great!</h1>
								<h3 className="primary mb-3">
									We Might Be Able To Get The Lowest Rate!
								</h3>
								<p>
									Click the call button below to be instantly
									connected to one of our friendly agents who
									will ask a few more<br />
									questions to find every available discount
									and get<br />
									you the the {' '}
									<strong>lowest possible rate</strong>.
								</p>

								<a
									className="btn btn-primary btn-lg btn-block  cta-button"
									href={`tel:${wizardData.clickToCallNumber}`}
								>
									<span>{wizardData.clickToCallNumber}</span>
								</a>
							</div>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Spinner />
			)}
		</Fragment>
	);
};

export default Complete;
