import { Fragment, useEffect, useState } from 'react';
import StepContainer from '../stepContainer/StepContainer';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { useHistory } from 'react-router';
import ButtonSelect from '../buttonSelect/ButtonSelect';
import maleIcon from '../../img/male-icon.png';
import femaleIcon from '../../img/female-icon.png';
import './GenderSelector.css';

const GenderSelector = ({ question, inputName, redirectTo, progress }) => {
	const [ { wizardData, progressData }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	const handleSelection = (selection) => {
		let tempData = { ...wizardData };

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : {
				...tempData,
				[inputName] : selection
			}
		});

		history.push(redirectTo);
	};

	return (
		<Fragment>
			<StepContainer>
				<h3>{question}</h3>

				<div className="yes-no-container">
					<ButtonSelect onClick={() => handleSelection('Male')}>
						{/* <img src={maleIcon} alt="Male" /> */}
						<span>Male</span>
					</ButtonSelect>

					<ButtonSelect onClick={() => handleSelection('Female')}>
						{/* <img src={femaleIcon} alt="Female" /> */}
						<span>Female</span>
					</ButtonSelect>
				</div>
			</StepContainer>
		</Fragment>
	);
};

export default GenderSelector;
