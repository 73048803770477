import { useEffect } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import cellphoneIcon from '../../img/cellphone-icon.png';
import './ThankYou.css';
import StepContainer from '../stepContainer/StepContainer';

const ThankYou = () => {
	const [ { progressData }, dispatch ] = useStateValue();

	useEffect(() => {
		// fire conversion scripts here
		// google
		window.gtag('event', 'conversion', {
			send_to : 'AW-824169956/hA3QCOj6vfcCEOSr_4gD'
		});
		// facebook
		window.fbq('track', 'Lead');

		// taboola
		window._tfa.push({ notify: 'event', name: 'lead', id: 1261991 });

		window.gtag('event', 'page_view', {
			page_title : 'Thank You',
			page_path  : window.location.href
		});

		dispatch({
			type         : actionTypes.SET_PROGRESS,
			progressData : 100
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	return (
		<StepContainer>
			<div className="thank-you-container">
				<h3 className="thank-you-title">Excellent!</h3>

				<img src={cellphoneIcon} alt="Cellphone Icon" />

				<h2 className="thank-you-subtitle">
					Keep an eye out for a text message with the details on your
					new auto insurance policy quote!
				</h2>
			</div>
		</StepContainer>
	);
};

export default ThankYou;
