import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import ButtonSelect from '../buttonSelect/ButtonSelect';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import Spinner from '../spinner/Spinner';
import StepContainer from '../stepContainer/StepContainer';
import './ListSelector.css';

const ListSelector = ({ list, question, inputName, redirectTo }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ { wizardData }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		let tempData = {
			...wizardData,
			[inputName] : null
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (wizardData && wizardData[inputName] && !isLoading) {
				history.push(redirectTo);
			}
		},
		[ wizardData ]
	);

	const handleSelection = (model) => {
		let tempData = {
			...wizardData,
			[inputName] : model
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});
	};

	return (
		<Fragment>
			{list && list.length > 0 && !isLoading ? (
				<Fragment>
					<StepContainer>
						<h3>{question}</h3>
						<div className="makes-buttons-container">
							{list.map((item) => (
								<ButtonSelect
									key={item}
									onClick={() => handleSelection(item)}
								>
									<span>{item}</span>
								</ButtonSelect>
							))}
						</div>
					</StepContainer>
				</Fragment>
			) : (
				<Fragment>
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ListSelector;
