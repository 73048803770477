import { useEffect, useState } from 'react';
import { useStateValue } from '../../StateProvider';
import './ProgressBar.css';

const ProgressBar = ({ location, amount }) => {
	const [ { progressData }, dispatch ] = useStateValue();
	const [ width, setWidth ] = useState('0%');

	useEffect(
		() => {
			if (progressData) {
				setWidth(`${parseInt(progressData)}%`);
			}
		},
		[ progressData ]
	);

	return (
		<div className="progress-bar-container">
			<div className="container">
				{/* <h3>
					{`We help ${location} drivers pay as little as`}{' '}
					<u>${amount} a month</u>!
				</h3> */}
				<div className="progress-bar">
					<div
						className="progress-bar-track"
						style={{ width: width }}
					/>
					<span>Progress {width}</span>
				</div>
			</div>
		</div>
	);
};

export default ProgressBar;
