import { useStateValue } from '../../StateProvider';
import './Footer.css';

const Footer = () => {
	const [ { wizardData, campaignData }, dispatch ] = useStateValue();

	return (
		<footer>
			<div className="container">
				<div className="footer-links-container">
					<a href={`tel:${campaignData.clickToCallNumber}`}>
						Contact Us
					</a>
					<a href="/pages/about" target="_blank">
						About Us
					</a>
					<a href="/pages/ads" target="_blank">
						About Our Ads
					</a>
					<a href="/pages/terms" target="_blank">
						Terms of Service
					</a>
					<a href="/pages/privacy" target="_blank">
						Privacy Policy
					</a>
				</div>
				<div className="mt-3">{campaignData.companyName}</div>
				<div>{campaignData.clickToCallNumber}</div>
			</div>
		</footer>
	);
};

export default Footer;
