import logo from './logo.svg';
import './App.css';
import AppHeader from './components/appHeader/AppHeader';
import StepContainer from './components/stepContainer/StepContainer';
import Wizard from './components/wizard/Wizard';
import Footer from './components/footer/Footer';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useStateValue } from './StateProvider';
import { actionTypes } from './reducer';
import { Route, Switch, useLocation } from 'react-router';
import About from './components/pages/about/About';
import Terms from './components/pages/terms/Terms';
import Privacy from './components/pages/privacy/Privacy';
import Ads from './components/pages/ads/Ads';
import Partners from './components/pages/partners/Partners';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import companies from './companies.json';
import { TrackFlow } from './Services';

function App() {
	const [
		{ flowData, wizardData, trackingData },
		dispatch
	] = useStateValue();
	const appLocation = useLocation();

	useEffect(() => {
		let sessionId = uuidv4();

		let tempFlowData = {
			LeadID : sessionId
		};

		dispatch({
			type     : actionTypes.SET_FLOW_DATA,
			flowData : tempFlowData
		});

		if (companies && companies.length > 0) {
			let currentUrl = window.location.origin;
			let matchFound = false;

			for (let i = 0; i < companies.length; i++) {
				let company = companies[i];

				if (currentUrl.indexOf(company.domainName) > -1) {
					matchFound = true;

					dispatch({
						type         : actionTypes.SET_CAMPAIGN_DATA,
						campaignData : {
							...company
						}
					});

					break;
				}
			}

			if (!matchFound) {
				dispatch({
					type         : actionTypes.SET_CAMPAIGN_DATA,
					campaignData : {
						...companies[0]
					}
				});
			}
		}
	}, []);

	useEffect(
		() => {
			// track location changes
			handleLocationChange();
		},
		[ appLocation ]
	);

	const handleLocationChange = async () => {
		let data = {
			...trackingData,
			CurrentUrl : window.location.href
		};

		if (!data.StartUrl) {
			data.StartUrl = window.location.href;
		}

		dispatch({
			type         : actionTypes.SET_TRACKING_DATA,
			trackingData : {
				...data
			}
		});

		console.log('tracking data', data);

		const response = await TrackFlow(data);
	};

	return (
		<div className="app">
			<AppHeader />

			<Switch>
				<Route path="/pages/partners">
					<Partners />
				</Route>
				<Route path="/pages/privacy">
					<Privacy />
				</Route>
				<Route path="/pages/terms">
					<Terms />
				</Route>
				<Route path="/pages/ads">
					<Ads />
				</Route>
				<Route path="/pages/about">
					<About />
				</Route>
				<Route path="/">
					<Wizard />
				</Route>
			</Switch>

			<Footer />

			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
			/>
		</div>
	);
}

export default App;
