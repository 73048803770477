import { Fragment, useEffect } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import ListSelector from '../listSelector/ListSelector';

const Tickets = ({ question, inputName, redirectTo, progress }) => {
	const providers = [ '0', '1', '2', '3+' ];
	const [ { progressData }, dispatch ] = useStateValue();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	return (
		<Fragment>
			<ListSelector
				list={providers}
				question={question}
				inputName={inputName}
				redirectTo={redirectTo}
			/>
		</Fragment>
	);
};

export default Tickets;
