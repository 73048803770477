import { Fragment, useEffect, useState } from 'react';
import StepContainer from '../stepContainer/StepContainer';
import './YesNoQuestion.css';
import yesAnimation from '../lottiePlayer/animations/yes.json';
import noAnimation from '../lottiePlayer/animations/no.json';
import LottiePlayer from '../lottiePlayer/LottiePlayer';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { useHistory } from 'react-router';
import ButtonSelect from '../buttonSelect/ButtonSelect';

const YesNoQuestion = ({ question, inputName, redirectTo, progress }) => {
	const [ { wizardData, progressData }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	const handleSelection = (selection) => {
		let tempData = { ...wizardData };

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : {
				...tempData,
				[inputName] : selection
			}
		});

		history.push(redirectTo);
	};

	return (
		<Fragment>
			<StepContainer>
				<h3>{question}</h3>

				<div className="yes-no-container">
					<ButtonSelect onClick={() => handleSelection('Yes')}>
						{/* <LottiePlayer
							animation={yesAnimation}
							width={100}
							height={100}
						/> */}
						<span>Yes</span>
					</ButtonSelect>

					<ButtonSelect onClick={() => handleSelection('No')}>
						{/* <LottiePlayer
							animation={noAnimation}
							width={100}
							height={100}
						/> */}
						<span>No</span>
					</ButtonSelect>
				</div>
			</StepContainer>
		</Fragment>
	);
};

export default YesNoQuestion;
