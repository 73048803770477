import React, { Fragment } from 'react';
import StepContainer from '../../stepContainer/StepContainer';
import '../Pages.css';

const Ads = () => {
	return (
		<Fragment>
			<StepContainer>
				<div className="legal-disclaimer">
					<h3>We use tracking technologies.</h3>

					<p>
						We collect personal information about users over time
						and across different websites when you use this website,
						app or service. We also have third parties that collect
						personal information this way. To do this, we use
						several common tracking tools. Our vendors may also use
						these tools. In this policy “we” and “us” refers to both
						EverQuote and our vendors. These may include browser
						cookies. We may also use web beacons, flash cookies, and
						similar technologies.
					</p>

					<h3>
						We use tracking technologies for a variety of reasons.
					</h3>

					<p>
						We use tracking tools, including cookies, on our
						websites. Third parties also use cookies on our sites.
						Cookies are small files that download when you access
						certain websites. To learn more, visit{' '}
						<a href="https://www.allaboutcookies.org/">here</a>.
						These pages also explain how you can manage and delete
						cookies. We use tracking tools:
					</p>

					<ul>
						<li>To recognize new or past customers.</li>
						<li>
							To improve our website and to better understand your
							visits on our platforms.
						</li>
						<li>
							To integrate with third party social media websites.
						</li>
						<li>
							To serve you with interest-based or targeted
							advertising (see below for more on interest-based
							advertising).{' '}
						</li>
						<li>
							To observe your behaviors and browsing activities
							over time across multiple websites or other
							platforms.{' '}
						</li>
						<li>
							To better understand the interests of our customers
							and our website visitors.
						</li>
						<li>
							To customize your experience when we show ads to
							you.
						</li>
					</ul>

					<h3>We engage in interest-based advertising.</h3>

					<p>
						We and our partners display interest-based advertising
						using information gathered about you over time across
						multiple websites or other platforms. This might include
						apps.
					</p>

					<p>
						Interest-based advertising or “online behavioral
						advertising” includes ads served to you after you leave
						our website, encouraging you to return. They also
						include ads we think are relevant based on your browsing
						habits or online activities. These ads might be served
						on websites or on apps. They might also be served in
						emails. We might serve these ads, or third parties may
						serve ads. They might be about our products or other
						companies’ products.
					</p>

					<h3>
						How do we gather relevant information about you for
						interest-based advertising?
					</h3>

					<p>
						To decide what is relevant to you, we use information
						you make available to us when you interact with us, our
						affiliates, and other third parties. We gather this
						information using the tracking tools described above.
						For example, we or our partners might look at your
						purchases or browsing behaviors. We or our partners
						might also look at your location. We might look at these
						activities on our platforms or the platforms of others.
					</p>

					<p>
						We work with third parties who help gather this
						information. These third parties might link your name or
						email address to other information they collect. That
						might include past purchases made offline or online. Or,
						it might include online usage information.
					</p>

					<h3>You can control certain tracking tools.</h3>

					<p>
						Your browser may give you the ability to control
						cookies. How you do so depends on the type of cookie.
						Certain browsers can be set to reject browser cookies.
						To find out more about how to enable, disable, or delete
						cookies from your web browser, please visit{' '}
						<a href="https://www.allaboutcookies.org/">here</a>. To
						control flash cookies, which we may use on certain
						websites from time to time, you can go{' '}
						<a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
							here
						</a>. Why? Because flash cookies cannot be controlled
						through your browser settings.
					</p>

					<p>
						<strong>
							Our Do Not Track Policy: Some browsers have “do not
							track” features that allow you to tell a website not
							to track you. These features are not all uniform. We
							do not currently respond to those signals.
						</strong>{' '}
						If you block cookies, certain features on our sites may
						not work. If you block or reject cookies, not all of the
						tracking described here will stop.
					</p>

					<p>
						Certain options you select are browser and device
						specific.
					</p>

					<h3>You can opt-out of behavioral advertising.</h3>

					<p>
						The Self-Regulatory Program for Online Behavioral
						Advertising program provides consumers with the ability
						to opt-out of having their online behavior recorded and
						used for advertising purposes.
					</p>

					<p>
						The Digital Advertising Alliance also offers a tool for
						opting out of the collection of cross-app data on a
						mobile device for interest-based advertising. To
						exercise choice for companies participating in this
						tool, download the AppChoices app{' '}
						<a href="https://www.aboutads.info/appchoices">here</a>.
					</p>

					<p>
						Certain choices you make are both browser and
						device-specific.
					</p>
				</div>
			</StepContainer>
		</Fragment>
	);
};

export default Ads;
