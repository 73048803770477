import { Fragment } from 'react';
import './Testimonial.css';

const Testimonial = ({ image, name, location, text }) => {
	return (
		<Fragment>
			<div className="testimonial-container">
				{image && (
					<Fragment>
						<img src={image} alt={name} />
					</Fragment>
				)}

				<div className="testimonial-content">
					<blockquote>{text}</blockquote>

					<div className="testimonial-person">
						<span className="testimonial-name">{name}</span>
						<span className="location">{location}</span>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Testimonial;
