import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import { SetProgress } from '../../Utils';
import ButtonSelect from '../buttonSelect/ButtonSelect';
import StepContainer from '../stepContainer/StepContainer';
import './YearSelector.css';

const YearSelector = ({ question, inputName, redirectTo, progress }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { wizardData, progressData }, dispatch ] = useStateValue();
	const [ buttonYears, setButtonYears ] = useState([]);
	const [ otherYears, setOtherYears ] = useState([]);
	const history = useHistory();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		// track google conversion
		window.gtag('event', 'conversion', {
			send_to : 'AW-824169956/XiPnCOaS9fsCEOSr_4gD'
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	useEffect(() => {
		let tempData = {
			...wizardData,
			[inputName] : null
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});

		const currentYear = new Date().getFullYear() + 1;
		let tempButtonYears = new Array();

		for (let i = currentYear; i > currentYear - 24; i--) {
			tempButtonYears.push(i);
		}

		// setButtonYears(tempButtonYears); //temp disabled since we're going all buttons for years

		let startFromYear = tempButtonYears[tempButtonYears.length - 1] - 1;
		let limitYear = 1980;

		let tempOtherYears = new Array();

		for (let i = startFromYear; i >= limitYear; i--) {
			//tempOtherYears.push(i);

			// push everything to buttons array for now
			tempButtonYears.push(i);
		}

		// setOtherYears(tempOtherYears); //temp disabled
		setButtonYears(tempButtonYears);
	}, []);

	useEffect(
		() => {
			if (wizardData && wizardData[inputName] && !isLoading) {
				history.push(redirectTo);
			}

			setIsLoading(false);
		},
		[ wizardData ]
	);

	const handleSelection = (year) => {
		let tempData = {
			...wizardData,
			[inputName] : year.toString()
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});
	};

	const handleDropdownChange = (e) => {
		handleSelection(e.target.value);
	};

	return (
		<Fragment>
			{buttonYears &&
			buttonYears.length > 0 && (
				<Fragment>
					<StepContainer>
						<h3>{question}</h3>
						<div className="years-buttons-container">
							{buttonYears.map((year) => (
								<ButtonSelect
									key={year}
									onClick={() => handleSelection(year)}
								>
									<span>{year}</span>
								</ButtonSelect>
							))}
						</div>

						{otherYears &&
						otherYears.length > 0 && (
							<Fragment>
								<div className="input-container">
									<select
										name="other-years"
										className="form-control form-control-lg mt-3"
										onChange={handleDropdownChange}
									>
										<option value="" key="select-previous">
											Select Previous Years
										</option>

										{otherYears.map((year) => (
											<option key={year} value={year}>
												{year}
											</option>
										))}
									</select>
								</div>
							</Fragment>
						)}
					</StepContainer>
				</Fragment>
			)}
		</Fragment>
	);
};

export default YearSelector;
