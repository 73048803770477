import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import StepContainer from '../stepContainer/StepContainer';
import providerLogos from '../../img/insurance-provider-logos.jpg';
import providerLogosM from '../../img/insurance-provider-logos-mobile.jpg';
import './ZipCodeB.css';
import Testimonial from '../testimonials/testimonial/Testimonial';
import johnImage from '../../img/JohnProfile.jpg';

const ZipCodeB = ({ question, inputName, redirectTo, progress }) => {
	const [
		{ wizardData, progressData, campaignData },
		dispatch
	] = useStateValue();
	const [ text, setText ] = useState('00000');
	const { zipCode } = useParams();
	const inputRef = useRef();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});

		let tempData = {
			...wizardData,
			[inputName] : text.trim()
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});
	}, []);

	useEffect(
		() => {
			dispatch({
				type         : actionTypes.SET_PROGRESS,
				progressData : progress
			});
		},
		[ progress ]
	);

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});
	}, []);

	const handleChange = (e) => {
		setText(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let tempData = {
			...wizardData,
			[inputName] : text.trim()
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});

		history.push(redirectTo);
	};

	return (
		<Fragment>
			{!zipCode && (
				<Fragment>
					<StepContainer>
						<h2 className="mb-5">
							{`We help ${campaignData.state} drivers`}{' '}
							<u>like you</u> pay as little as <u>$28 a month</u>{' '}
							for your auto insurance payment!
						</h2>

						<h3 className="mb-5">Let's See If You Qualify...</h3>

						<div className="inputs-container">
							<form autoComplete="off" onSubmit={handleSubmit}>
								<div className="buttons-container">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
									>
										<span>Click Here</span>
									</button>
								</div>
							</form>
						</div>

						<style type="text/css">
							{`.progress-bar-container {
								padding: 20px 0 !important;
							}
							.progress-bar-container h3 {
								display: none !important;
							}
							.testimonial-container {
    							font-size: 18px;
							}
							.testimonial-container span.location {
    							margin-bottom: 0px;
							}
							@media screen and (max-width: 767px) {
								.progress-bar-container > .container {
									padding: 0 20px !important;
								}
								.wizard-step h2 {
									font-size: 20px;
								}
								.wizard-step h3 {
									font-size: 30px;
								}
							}
							`}
						</style>
					</StepContainer>

					<Testimonial
						image={johnImage}
						name="John S."
						location={campaignData.testimonialLocation}
						text="This site helped me quickly find the best rate without having to make several phone calls and be on the phone for hours getting quotes from different insurance agents. It only took a minunte and I was able to save $62 per month!"
					/>

					<div className="provider-logos container-fluid mt-5 show-desktop">
						<img
							src={providerLogos}
							alt="Insurance Provider Logos"
						/>
					</div>
					<div className="provider-logos container-fluid mt-5 show-mobile">
						<img
							src={providerLogosM}
							alt="Insurance Provider Logos"
						/>
					</div>

				</Fragment>
			)}
		</Fragment>
	);
};

export default ZipCodeB;
