import axios from 'axios';

const apiHost = window.location.origin + '/Service/';

export const GetCarMakes = async (data) => {
	const response = await axios
		.post(apiHost + 'CarMakerModelDBService.asmx/GetCarMakes', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const GetCarModels = async (data) => {
	const response = await axios
		.post(apiHost + 'CarMakerModelDBService.asmx/GetCarModels', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const AddNewLead = async (data) => {
	let postUrl = apiHost + 'LiveCallsService.asmx/AddNewLead';

	if (data.zapURL && data.zapURL.trim() !== '') {
		postUrl = data.zapURL;
	}

	const response = await axios
		.post(postUrl, data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};

export const TrackFlow = async (data) => {
	const response = await axios
		.post(apiHost + 'LiveCallsService.asmx/TrackFlow', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data.d;
};
