import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { useStateValue } from '../../StateProvider';
import './AppHeader.css';

const AppHeader = () => {
	const [ { wizardData, campaignData }, dispatch ] = useStateValue();
	const history = useHistory();

	const handleLogoClick = () => {
		history.push('/');
	};

	return (
		<header className="app-header">
			{/* <span>We care about your privacy</span> */}
			<div className="app-logo" onClick={handleLogoClick} />

			{campaignData &&
			campaignData.clickToCallNumber && (
				<Fragment>
					<span>
						Call Now:{' '}
						<a href={`tel:${campaignData.clickToCallNumber}`}>
							{campaignData.clickToCallNumber}
						</a>
					</span>
				</Fragment>
			)}
		</header>
	);
};

export default AppHeader;
